import React from "react";
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoPencil,
} from "react-icons/io5";
import NumberFormat from "react-number-format";
import Center from "../../../core/ui/table/Center";
import Table from "../../../core/ui/table/Table";
import Td from "../../../core/ui/table/Td";
import moment from "moment";
import { FaTrashAlt } from "react-icons/fa";

const TrackTable = (props) => {
  const { data, onEdit, onDelete } = props;
  const table = {
    width: [50, 200, 80],
    header: ["No", "Name", "Action"],
  };
  return (
    <Table table={table}>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index}>
              <Td>
                <p className="text-center">{index + 1}</p>
              </Td>
              <Td>
                <p>{item}</p>
              </Td>
              <Td>
                <Center>
                  {/* <button
                    onClick={() => onEdit(item)}
                    className="border border-blue-700 p-0.5 rounded-lg"
                  >
                    <IoPencil className="text-blue-700" />
                  </button> */}
                  <button
                    onClick={() => onDelete(item)}
                    className="border border-red-700 p-0.5 rounded-lg"
                  >
                    <FaTrashAlt className="text-red-700" />
                  </button>
                </Center>
              </Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TrackTable;
