import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/core/ui/Layout";
import PaymentAffiliate from "../../components/page/ecommerce/payment_history/affiliate/PaymentAffiliate";
import Self from "../../components/page/ecommerce/payment_history/self/Self";

const PaymentHistory = (props) => {
  const { affiliate, isAuthenticated } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);
  useEffect(() => {
    document.title = "Payment History | Affiliate Quamus";
  }, []);
  return (
    <Fragment>
      <Layout title="Payment History">
        <div className="space-x-2 flex justify-start items-start">
          <Self />
          {affiliate && affiliate.level === 1 && <PaymentAffiliate />}
        </div>
      </Layout>
    </Fragment>
  );
};

const maps = (state) => ({
  affiliate: state.auth.affiliate,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(maps, {})(PaymentHistory);
