import React from "react";

const Input = (props) => {
  const { disabled, title, type, accept, value, name, onChange } = props;
  return (
    <div>
      <p className="text-slate-500 text-xs font-bold mb-1">
        {title.toUpperCase()}
      </p>
      <input
        disabled={disabled ? disabled : false}
        type={type}
        className="w-full outline-none border border-slate-300 p-1 font-bold text-sm disabled:text-slate-500"
        name={name}
        value={value}
        onChange={onChange}
        accept={accept ? accept : ""}
      />
    </div>
  );
};

export default Input;
