import React from "react";

const Header = (props) => {
  const { table } = props;
  return (
    <thead>
      <tr className="bg-slate-100">
        {table.header.map((item, index) => {
          return (
            <th
              key={index}
              style={{ width: `${table.width[index]}px` }}
              className={`border border-slate-300 p-1`}
            >
              {item}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default Header;
