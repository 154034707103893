// export const AUTH_API_URL = "http://localhost:8100";
// export const COURSE_API_URL = "http://localhost:8101";
// export const ECOMMERCE_API_URL = "http://localhost:8102";
// export const SHIPMENT_API_URL = "http://localhost:8103";
// export const MUNAWWIR_API_URL = "http://localhost:8104";
// export const GAME_API_URL = "https://api.quamus.id/game";
// export const XENDIT_CHECKOUT_URL = "https://checkout-staging.xendit.co/web";


export const AUTH_API_URL = "https://api.quamus.id/auth";
export const COURSE_API_URL = "https://api.quamus.id/course";
export const ECOMMERCE_API_URL = "https://api.quamus.id/ecommerce";
export const SHIPMENT_API_URL = "https://api.quamus.id/shipment";
export const GAME_API_URL = "https://api.quamus.id/game";

export const CURRENT_URL = "https://quamus.id";
