import React, { Fragment, useState } from "react";
import { IoSave, IoClose } from "react-icons/io5";
import Footer from "./Footer";

const Panel = (props) => {
  const { title, isValid, isSending, onClose, onSubmit } = props;
  return (
    <div className="fixed z-30 top-0 left-0 w-full h-screen flex justify-center items-center">
      <div className="fixed top-0 left-0 w-full h-screen bg-black opacity-80 z-10"></div>
      <div className="bg-white rounded-lg z-20 p-3">
        <p className="font-bold text-left">{title.toUpperCase()}</p>
        <hr className="my-2 border-slate-500" />
        <form onSubmit={onSubmit} encType="multipart/form-data">
          <div className="max-h-popupForm overflow-y-auto mb-3">
            {props.children}
          </div>
          <Footer isValid={isValid} isSending={isSending} onClose={onClose} />
        </form>
      </div>
    </div>
  );
};

export default Panel;
