import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import { IoMail, IoLockClosedSharp } from "react-icons/io5";
import validator from "validator";
import { SET_AUTHENTICATED, SET_USER } from "../../stores/actions/types";
import { AUTH_API_URL } from "../../stores/actions/urls";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Login = (props) => {
  const navigate = useNavigate();
  const { isAuthenticated } = props;
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (isValid) {
      setIsSending(true);
      login();
    }
  };
  const login = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const body = JSON.stringify(formData);
    await axios
      .post(`${AUTH_API_URL}/login/`, body, config)
      .then((res) => {
        checkUser(res.data.access, res.data.session);
      })
      .catch((e) => {
        loginFailedHandler();
      });
  };
  const checkUser = async (token, session) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
        Accept: "application/json",
      },
    };
    await axios
      .get(`${AUTH_API_URL}/user/`, config)
      .then((res) => {
        if (
          res.data.is_staff &&
          (res.data.role === "affiliate" ||
            res.data.role === "affiliate_affiliate")
        ) {
          dispatch({
            type: SET_AUTHENTICATED,
            payload: true,
          });
          dispatch({
            type: SET_USER,
            payload: res.data,
          });
          Cookies.set("leofranz", token);
          Cookies.set("flyingorcha", session);
        } else {
          loginFailedHandler();
        }
      })
      .catch((e) => {
        loginFailedHandler();
      });
  };
  const loginFailedHandler = () => {
    setIsError(true);
    setIsSending(false);
    dispatch({
      type: SET_AUTHENTICATED,
      payload: false,
    });
    dispatch({
      type: SET_USER,
      payload: null,
    });
    Cookies.remove("leofranz");
    Cookies.remove("flyingorcha");
  };
  useEffect(() => {
    document.title = "Login - Affiliate Quamus";
  }, []);
  useEffect(() => {
    if (isSending) {
      setIsValid(false);
    } else {
      if (validator.isEmail(formData.email) && formData.password.length > 5) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [isSending, formData]);
  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/");
    }
  }, [isAuthenticated]);
  return (
    <div className="bg-gradient-to-b from-cyan-500 to-blue-500 w-full h-screen flex justify-center items-center">
      <div className="bg-white w-[400px] p-6 drop-shadow-md rounded-md flex flex-col justify-center items-center">
        <img className="w-12 mb-2" src={Logo} alt="Logo" />
        <p className="text-center font-bold text-xl mb-2">Login</p>
        {isError && (
          <div className="text-sm mb-2 w-full">
            <p className="text-left text-red-600">
              Email dan Password tidak valid.
            </p>
          </div>
        )}
        <form className="w-full" onSubmit={submitHandler}>
          <div className="w-full mb-4 space-y-2 text-sm">
            <div className="flex justify-start items-center space-x-2 border border-gray-200 p-2 w-full">
              <IoMail className="text-gray-600" />
              <input
                type="text"
                name="email"
                placeholder="Email"
                className="outline-none flex-auto"
                onChange={changeHandler}
                onFocus={() => setIsError(false)}
              />
            </div>
            <div className="flex justify-start items-center space-x-2 border border-gray-200 p-2 w-full">
              <IoLockClosedSharp className="text-gray-600" />
              <input
                type="password"
                name="password"
                placeholder="Password"
                className="outline-none flex-auto"
                onChange={changeHandler}
                onFocus={() => setIsError(false)}
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={!isValid}
            className="bg-primary-200 hover:bg-primary-100 text-white w-full py-2 font-bold disabled:bg-gray-400"
          >
            {isSending ? (
              <div>
                <div
                  className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full mr-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                <p className="inline">Logging In...</p>
              </div>
            ) : (
              <p className="inline">Login</p>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

const maps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(maps, {})(Login);
