import React from "react";
import NumberFormat from "react-number-format";

const InputNumeric = (props) => {
  const { disabled, title, value, name, onChange } = props;
  return (
    <div>
      <p className="text-slate-500 text-xs font-bold mb-1">
        {title.toUpperCase()}
      </p>
      <NumberFormat
        disabled={disabled ? disabled : false}
        className="w-full outline-none border border-slate-300 p-1 font-bold text-sm disabled:text-slate-500"
        thousandsGroupStyle="thousand"
        name={name}
        value={value}
        thousandSeparator="."
        decimalSeparator=","
        displayType="input"
        allowNegative={false}
        onChange={onChange}
      />
    </div>
  );
};

export default InputNumeric;
