import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/core/ui/Layout";
import { ECOMMERCE_API_URL } from "../../stores/actions/urls";
import axios from "axios";
import Cookies from "js-cookie";
import Card from "../../components/core/ui/Card";
import ProductTable from "../../components/page/ecommerce/product/Table";
import { toast } from "wc-toast";
import Select from "../../components/core/ui/form/Select";
import { SET_AFFILIATE } from "../../stores/actions/types";
import TrackTable from "../../components/page/ecommerce/track/Table";
import TrackForm from "../../components/page/ecommerce/track/Form";
import AddButton from "../../components/core/ui/table/AddButton";

const Track = (props) => {
  const dispatch = useDispatch();
  const [isFormShow, setIsFormShow] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [formItem, setFormItem] = useState();
  const [inputData, setInputData] = useState({});
  const { user, affiliate } = props;
  const typeList = [
    { value: "utm_source", label: "utm_source" },
    { value: "utm_medium", label: "utm_medium" },
    { value: "utm_campaign", label: "utm_campaign" },
    { value: "utm_term", label: "utm_term" },
    { value: "utm_content", label: "utm_content" },
  ];
  const [selectedType, setSelectedType] = useState("utm_source");
  const [data, setData] = useState();
  const changeHandler = (e) => {
    setSelectedType(e.target.value);
  };

  const loadData = useCallback(async (user) => {
    dispatch({
      type: SET_AFFILIATE,
      payload: null,
    });

    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(`${ECOMMERCE_API_URL}/affiliate/${user.email}`, config)
        .then((res) => {
          dispatch({
            type: SET_AFFILIATE,
            payload: res.data,
          });
        });
    }
  }, []);

  const showAddFormHandler = () => {
    setFormItem();
    setFormTitle(`Add ${selectedType}`);
    setIsFormShow(true);
  };
  const showEditFormHandler = (item) => {
    setFormItem(item);
    setFormTitle(`Update ${selectedType}`);
    setIsFormShow(true);
  };
  const deleteItem = (item) => {
    if (window.confirm("Are you sure to delete data?")) {
      deleteData(item);
    }
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    storeData();
  };
  const storeData = async () => {
    setIsSending(true);
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      const body = JSON.stringify({
        action: "push",
        type: selectedType,
        value: inputData.value,
      });
      await axios
        .post(`${ECOMMERCE_API_URL}/v2/affiliate/utm`, body, config)
        .then((res) => {
          setIsFormShow(false);
          setIsSending(false);
          loadData(user);
          toast.success("Data inserted successfully!");
        })
        .catch((e) => {
          setIsFormShow(false);
          setIsSending(false);
          toast.error(e.response.data.error);
        });
    }
  };

  const deleteData = async (item) => {
    setIsSending(true);
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      const body = JSON.stringify({
        action: "pull",
        type: selectedType,
        value: item,
      });
      await axios
        .post(`${ECOMMERCE_API_URL}/v2/affiliate/utm`, body, config)
        .then((res) => {
          setIsSending(false);
          loadData(user);
          toast.success("Data deleted successfully!");
        })
        .catch((e) => {
          setIsSending(false);
          toast.error(e.response.data.error);
        });
    }
  };

  useEffect(() => {
    setData([]);
    if (affiliate) {
      switch (selectedType) {
        case "utm_source":
          if (affiliate.utm_source) {
            setData(affiliate.utm_source);
          }
          break;
        case "utm_medium":
          if (affiliate.utm_medium) {
            setData(affiliate.utm_medium);
          }
          break;
        case "utm_campaign":
          if (affiliate.utm_campaign) {
            setData(affiliate.utm_campaign);
          }
          break;
        case "utm_term":
          if (affiliate.utm_term) {
            setData(affiliate.utm_term);
          }
          break;
        case "utm_content":
          if (affiliate.utm_content) {
            setData(affiliate.utm_content);
          }
          break;
        default:
          break;
      }
    }
  }, [affiliate, selectedType]);

  useEffect(() => {
    document.title = "Track Ads | Affiliate Quamus";
  }, []);

  useEffect(() => {
    if (user) {
      loadData(user);
    }
  }, [user]);

  return (
    <Fragment>
      {isFormShow && (
        <TrackForm
          width={300}
          item={formItem}
          title={formTitle}
          isSending={isSending}
          onClose={() => setIsFormShow(false)}
          onSubmit={formSubmitHandler}
          setInputData={setInputData}
        />
      )}
      <Layout title="Ads Track">
        <div className="w-full bg-slate-200 mb-2 py-2 px-4 space-x-2 rounded-md drop-shadow-sm flex justify-start items-end">
          <div className="w-[150px]">
            <Select
              list={typeList}
              provideNull={false}
              title="UTM Type"
              name="utm"
              value={selectedType}
              onChange={changeHandler}
            />
          </div>
          <AddButton onClick={() => showAddFormHandler()} />
        </div>
        {data ? (
          <Card>
            <TrackTable
              data={data}
              onDelete={deleteItem}
              onEdit={showEditFormHandler}
            />
          </Card>
        ) : (
          <p className="text-sm">Loading...</p>
        )}
      </Layout>
    </Fragment>
  );
};

const maps = (state) => ({
  user: state.auth.user,
  affiliate: state.auth.affiliate,
});

export default connect(maps, {})(Track);
