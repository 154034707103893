import react, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const InputDate = (props) => {
  const { title, value, name, onChange } = props;
  const [startDate, setStartDate] = useState(new Date());

  const handleOnchange = (val) => {
    const e = {
      target: {
        type: "datepicker",
        name: name,
        value:
          val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate(),
      },
    };
    onChange(e);
  };
  return (
    <div>
      <p className="text-slate-500 text-xs font-bold mb-1">
        {title.toUpperCase()}
      </p>
      <DatePicker
        minDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
        maxDate={
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        }
        className="w-full outline-none border border-slate-300 p-1 font-bold text-sm disabled:text-slate-500"
        dateFormat="dd/MM/yyyy"
        selected={new Date(Date.parse(value))}
        onChange={(date) => handleOnchange(date)}
      />
    </div>
  );
};

export default InputDate;
