import React from "react";
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoPencil,
} from "react-icons/io5";
import NumberFormat from "react-number-format";
import Center from "../../../core/ui/table/Center";
import Table from "../../../core/ui/table/Table";
import Td from "../../../core/ui/table/Td";
import moment from "moment";

const SalesTable = (props) => {
  const { data, onEdit } = props;
  const table = {
    width: [50, 90, 180, 300, 200, 100, 100],
    header: ["No", "Date", "Name", "Product", "Ads Track", "Price", "Fee"],
  };
  return (
    <Table table={table}>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index}>
              <Td>
                <p className="text-center">{index + 1}</p>
              </Td>
              <Td>
                <p className="text-center">
                  {moment(item.created_at.$date).format("D-M-YYYY")}
                </p>
              </Td>
              <Td>
                <p className="font-bold text-blue-400">{item.buyer_name}</p>
                <p className="text-slate-500 text-xs">{item.buyer_email}</p>
                <p className="text-slate-500 text-xs">{item.buyer_phone}</p>
              </Td>
              <Td>
                <p>{item.product_name}</p>
              </Td>
              <Td>
                {item.affiliate.utm_source && item.affiliate.utm_source.length > 0 && (
                  <p className="text-xs">
                    utm_source: {item.affiliate.utm_source}
                  </p>
                )}
                {item.affiliate.utm_medium && item.affiliate.utm_medium.length > 0 && (
                  <p className="text-xs">
                    utm_medium: {item.affiliate.utm_medium}
                  </p>
                )}
                {item.affiliate.utm_campaign && item.affiliate.utm_campaign.length > 0 && (
                  <p className="text-xs">
                    utm_campaign: {item.affiliate.utm_campaign}
                  </p>
                )}
                {item.affiliate.utm_term && item.affiliate.utm_term.length > 0 && (
                  <p className="text-xs">utm_term: {item.affiliate.utm_term}</p>
                )}
                {item.affiliate.utm_content && item.affiliate.utm_content.length > 0 && (
                  <p className="text-xs">
                    utm_content: {item.affiliate.utm_content}
                  </p>
                )}
              </Td>
              <Td>
                <p className="text-right">
                  <NumberFormat
                    value={item.price}
                    displayType={"text"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    prefix={"Rp "}
                  />
                </p>
              </Td>
              <Td>
                <p className="text-right">
                  <NumberFormat
                    value={item.fee}
                    displayType={"text"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    prefix={"Rp "}
                  />
                </p>
              </Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default SalesTable;
