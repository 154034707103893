import React, { useCallback, useEffect, useState } from "react";
import Input from "../../../core/ui/form/Input";
import Panel from "../../../core/ui/popup/Panel";

const TrackForm = (props) => {
  const { width, item, title, isSending, onClose, onSubmit, setInputData } =
    props;
  const [isValid, setIsValid] = useState(true);
  const [formData, setFormData] = useState({
    value: "",
  });
  const changeHandler = (e) => {
    if (e.target) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (isSending) {
      setIsValid(false);
      setInputData();
    } else {
      if (formData.value.length > 0) {
        setIsValid(true);
        setInputData(formData);
      } else {
        setIsValid(false);
        setInputData();
      }
    }
  }, [formData, isSending]);

  useEffect(() => {
    if (item) {
      setFormData({
        value: item,
      });
    }
  }, [item]);

  return (
    <Panel
      title={title}
      onClose={onClose}
      onSubmit={onSubmit}
      isValid={isValid}
      isSending={isSending}
    >
      <div style={{ width: `${width}px` }} className="grid grid-cols-12 gap-3">
        <div className="col-span-12">
          <Input
            title="value"
            name="value"
            type="text"
            value={formData.value}
            onChange={changeHandler}
          />
        </div>
      </div>
    </Panel>
  );
};

export default TrackForm;
