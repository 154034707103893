import React, { useEffect } from "react";
import { connect } from "react-redux";

const Content = (props) => {
  const { isSidebarOpen } = props;
  return (
    <div
      className={`${
        isSidebarOpen ? "ml-[260px]" : "ml-[80px]"
      } mt-[60px] bg-slate-100 h-content overflow-auto transition-all`}
    >
      <div className="p-6 w-full overflow-x-auto">{props.children}</div>
    </div>
  );
};

const maps = (state) => ({
  isSidebarOpen: state.ui.isSidebarOpen,
});

export default connect(maps, {})(Content);
