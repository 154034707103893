import React from "react";
import Layout from "./hoc/Layout";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import store from "./store";

import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import Product from "./pages/ecommerce/Product";
import Affiliate from "./pages/ecommerce/Affiliate";
import Tier from "./pages/ecommerce/Tier";
import Sales from "./pages/ecommerce/Sales";
import PaymentHistory from "./pages/ecommerce/PaymentHistory";
import UnpaidOrder from "./pages/ecommerce/UnpaidOrder";
import Setting from "./pages/ecommerce/Setting";
import Track from "./pages/ecommerce/Track";
import GenerateLink from "./pages/ecommerce/GenerateLink";
import VerifiedUser from "./pages/auth/VerifiedUser";

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route exact path="/" element={<Navigate to="/dashboard" />} />
            <Route exact path="/dashboard" element={<Home />} />
            <Route exact path="/product" element={<Product />} />
            <Route exact path="/verified-user" element={<VerifiedUser />} />
            <Route exact path="/track" element={<Track />} />
            <Route exact path="/generate" element={<GenerateLink />} />
            <Route exact path="/affiliate" element={<Affiliate />} />
            <Route exact path="/tier" element={<Tier />} />
            <Route exact path="/sales" element={<Sales />} />
            <Route exact path="/unpaid-order" element={<UnpaidOrder />} />
            <Route exact path="/payment-history" element={<PaymentHistory />} />
            <Route exact path="/setting" element={<Setting />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
