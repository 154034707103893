import React, { Fragment, useState } from "react";
import { IoCopy } from "react-icons/io5";
import NumberFormat from "react-number-format";
import Center from "../../../core/ui/table/Center";
import Table from "../../../core/ui/table/Table";
import Td from "../../../core/ui/table/Td";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "wc-toast";
import { connect } from "react-redux";
import { CURRENT_URL } from "../../../../stores/actions/urls";

const ProductTable = (props) => {
  const { data, affiliate } = props;
  const table = {
    width: [50, 250, 150],
    header: ["No", "Title", "Price"],
  };
  return (
    <Table table={table}>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index}>
              <Td>
                <p className="text-center">{index + 1}</p>
              </Td>
              <Td>
                <p>{item.title}</p>
              </Td>
              <Td>
                <p className="text-right">
                  Original:{" "}
                  <NumberFormat
                    value={item.original_price}
                    displayType={"text"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    prefix={"Rp "}
                  />
                </p>
                <p className="text-right">
                  Sale:{" "}
                  <NumberFormat
                    value={item.sale_price}
                    displayType={"text"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    prefix={"Rp "}
                  />
                </p>
              </Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const maps = (state) => ({
  affiliate: state.auth.affiliate,
});

export default connect(maps, {})(ProductTable);
