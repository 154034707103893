import React, { Fragment } from "react";
import Content from "./Content";
import Navbar from "./Navbar";
import Sidebar from "./sidebar/Sidebar";

const Layout = (props) => {
  const { title } = props;
  return (
    <Fragment>
      <Navbar />
      <Sidebar />
      <Content>
        <p className="text-lg font-bold">{title}</p>
        <hr className="mt-2 mb-4 border-slate-300" />
        {props.children}
      </Content>
    </Fragment>
  );
};

export default Layout;
