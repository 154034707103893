import React, { useEffect, useState } from "react";
import InputDate from "../../core/ui/form/DatePicker";
import Panel from "../../core/ui/popup/Panel";
import moment from "moment";
import InputNumeric from "../../core/ui/form/InputNumeric";

const BalanceForm = (props) => {
  const { width, item, title, isSending, onClose, onSubmit, setInputData } =
    props;
  const [isValid, setIsValid] = useState(true);
  const [formData, setFormData] = useState({
    date: moment().format("YYYY-M-D"),
    amount: "",
  });
  const changeHandler = (e) => {
    if (e.target) {
      if (e.target.type === "checkbox") {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    }
  };

  useEffect(() => {
    if (isSending) {
      setIsValid(false);
      setInputData();
    } else {
      if (formData.date.length > 0 && formData.amount.length > 0) {
        setIsValid(true);
        setInputData(formData);
      } else {
        setIsValid(false);
        setInputData();
      }
    }
  }, [formData, isSending]);

  useEffect(() => {
    if (item) {
      setFormData({
        date: moment().format("YYYY-M-D"),
        amount: String(item.balance),
      });
    }
  }, [item]);

  //   useEffect(() => {
  //     console.log(formData);
  //   }, [formData]);
  return (
    <Panel
      title={title}
      onClose={onClose}
      onSubmit={onSubmit}
      isValid={isValid}
      isSending={isSending}
    >
      <div style={{ width: `${width}px` }} className="grid grid-cols-12 gap-3">
        <div className="col-span-6">
          <InputDate
            title="date"
            name="date"
            value={formData.date}
            onChange={changeHandler}
          />
        </div>
        <div className="col-span-6">
          <InputNumeric
            disabled={true}
            title="amount"
            name="amount"
            value={formData.amount}
            onChange={changeHandler}
          />
        </div>
      </div>
    </Panel>
  );
};

export default BalanceForm;
