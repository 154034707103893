import React, { useState, useCallback, useEffect } from "react";
import Card from "../../../../core/ui/Card";
import axios from "axios";
import Cookies from "js-cookie";
import { ECOMMERCE_API_URL } from "../../../../../stores/actions/urls";
import Table from "../../../../core/ui/table/Table";
import Td from "../../../../core/ui/table/Td";
import NumberFormat from "react-number-format";
import moment from "moment";

const Self = (props) => {
  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    setData();
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(`${ECOMMERCE_API_URL}/affiliate/self-payment/`, config)
        .then((res) => {
          setData(res.data);
        });
    }
  });

  useEffect(() => {
    loadData();
  }, []);
  const table = {
    width: [50, 110, 140],
    header: ["No", "Date", "Amount"],
  };
  return (
    <Card>
      {data ? (
        <Table table={table}>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <Td>
                    <p className="text-center">{index + 1}</p>
                  </Td>
                  <Td>
                    <p className="text-center">
                      {moment(item.created_at.$date).format("D-M-YYYY")}
                    </p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.amount}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={"Rp "}
                      />
                    </p>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <p className="text-sm">Loading...</p>
      )}
    </Card>
  );
};

export default Self;
