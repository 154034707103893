import axios from "axios";
import Cookies from "js-cookie";
import { SET_AUTHENTICATED, SET_USER, SET_AFFILIATE } from "./types";
import { AUTH_API_URL, ECOMMERCE_API_URL } from "./urls";

export const loadUser = () => async (dispatch) => {
  dispatch({
    type: SET_AUTHENTICATED,
    payload: null,
  });
  dispatch({
    type: SET_AFFILIATE,
    payload: null,
  });
  if (Cookies.get("leofranz")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${Cookies.get("leofranz")}`,
        Accept: "application/json",
      },
    };
    await axios
      .get(`${AUTH_API_URL}/user/`, config)
      .then((res) => {
        if (
          res.data.is_staff &&
          (res.data.role === "affiliate" ||
            res.data.role === "affiliate_affiliate")
        ) {
          dispatch({
            type: SET_AUTHENTICATED,
            payload: true,
          });
          dispatch({
            type: SET_USER,
            payload: res.data,
          });
        } else {
          dispatch({
            type: SET_AUTHENTICATED,
            payload: false,
          });
          dispatch({
            type: SET_USER,
            payload: null,
          });
          Cookies.remove("leofranz");
          Cookies.remove("flyingorcha");
        }
      })
      .catch((e) => {
        dispatch({
          type: SET_AUTHENTICATED,
          payload: false,
        });
        dispatch({
          type: SET_USER,
          payload: null,
        });
        Cookies.remove("leofranz");
        Cookies.remove("flyingorcha");
      });
  } else {
    dispatch({
      type: SET_AUTHENTICATED,
      payload: false,
    });
    dispatch({
      type: SET_USER,
      payload: null,
    });
  }
};

export const loadAffiliate = (email) => async (dispatch) => {
  dispatch({
    type: SET_AFFILIATE,
    payload: null,
  });
  if (Cookies.get("leofranz")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${Cookies.get("leofranz")}`,
        Accept: "application/json",
      },
    };
    await axios
      .get(`${ECOMMERCE_API_URL}/affiliate/${email}`, config)
      .then((res) => {
        dispatch({
          type: SET_AFFILIATE,
          payload: res.data,
        });
      });
  }
};

export const getOwnedVerifiedUser =
  (month, year, setData) => async (dispatch) => {
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(`${AUTH_API_URL}/owned-user-verified/${month}/${year}`, config)
        .then((res) => {
          setData(res.data);
        });
    }
  };
