import React from "react";
import Table from "../../../core/ui/table/Table";
import Td from "../../../core/ui/table/Td";
import moment from "moment";
import NumberFormat from "react-number-format";
import { FaExternalLinkAlt } from "react-icons/fa";
import Center from "../../../core/ui/table/Center";
import { Link } from "react-router-dom";
import { CURRENT_URL } from "../../../../stores/actions/urls";

const OrderTable = (props) => {
  const { data, onEdit } = props;
  const table = {
    width: [50, 90, 180, 400, 100],
    header: [
      "No",
      "Date",
      "Name",
      "Product",
      "Price",
      // "Expired",
      // "Link",
    ],
  };
  return (
    <Table table={table}>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index}>
              <Td>
                <p className="text-center">{index + 1}</p>
              </Td>
              <Td>
                <p className="text-center">
                  {moment(item.created.$date).format("D-M-YYYY")}
                </p>
              </Td>
              <Td>
                <p className="font-bold text-blue-400">{item.user.name}</p>
                <p className="text-slate-500 text-xs">{item.user.phone}</p>
                <p className="text-slate-500 text-xs">{item.user.email}</p>
              </Td>
              <Td>
                <p>{item.product.title}</p>
              </Td>
              <Td>
                <p className="text-right">
                  <NumberFormat
                    value={item.order_price}
                    displayType={"text"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    prefix={"Rp "}
                  />
                </p>
              </Td>
              {/* <Td>
                <p className="text-center">
                  {moment(item.expired_at.$date).format("D-M-YYYY")}
                </p>
              </Td>
              <Td>
                <Center>
                  <button
                    onClick={() =>
                      window.open(
                        `${CURRENT_URL}/checkout-payment/${item.xendit_invoice_id}`,
                        "_blank"
                      )
                    }
                    className="text-blue-400"
                  >
                    <FaExternalLinkAlt />
                  </button>
                </Center>
              </Td> */}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default OrderTable;
