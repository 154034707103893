import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  BiHome,
  BiCart,
  BiLinkAlt,
  BiBarChartAlt,
  BiLineChart,
  BiHistory,
  BiNews,
  BiLinkExternal,
  BiUserCheck,
} from "react-icons/bi";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { IoSettings } from "react-icons/io5";

const Button = (props) => {
  const { pathname } = useLocation();
  const { isSidebarOpen, item } = props;
  return (
    <Link
      to={`/${item.link}`}
      className={`flex ${
        isSidebarOpen ? "justify-start" : "justify-center"
      } items-center space-x-2 
      ${
        pathname === "/" + item.link ? "bg-slate-300" : "bg-slate-100"
      } rounded-md p-2`}
    >
      {item.link === "dashboard" && (
        <BiHome className="text-purple-600 text-xl" />
      )}
      {item.link === "product" && <BiCart className="text-red-400 text-xl" />}
      {item.link === "tier" && (
        <BiBarChartAlt className="text-blue-300 text-xl" />
      )}
      {item.link === "affiliate" && (
        <BiLinkAlt className="text-yellow-500 text-xl" />
      )}
      {item.link === "sales" && (
        <BiLineChart className="text-green-400 text-xl" />
      )}
      {item.link === "payment-history" && (
        <BiHistory className="text-blue-500 text-xl" />
      )}
      {item.link === "unpaid-order" && (
        <FaFileInvoiceDollar className="text-blue-500 text-xl" />
      )}
      {item.link === "generate" && (
        <BiLinkExternal className="text-gray-400 text-xl" />
      )}
      {item.link === "verified-user" && (
        <BiUserCheck className="text-red-500 text-xl" />
      )}
      {item.link === "track" && <BiNews className="text-orange-300 text-xl" />}
      {item.link === "setting" && <IoSettings className="text-black text-xl" />}
      {isSidebarOpen && (
        <p className="text-slate-700 font-bold">{item.title}</p>
      )}
    </Link>
  );
};

export default Button;
