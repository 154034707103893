import React, { useEffect } from "react";
import Logo from "../../../assets/images/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  SET_AUTHENTICATED,
  SET_USER,
  SET_SIDEBAR,
} from "../../../stores/actions/types";

const Navbar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user, isSidebarOpen } = props;

  const logoutHandler = (props) => {
    if (window.confirm("Are you sure to logout?")) {
      dispatch({
        type: SET_AUTHENTICATED,
        payload: false,
      });
      dispatch({
        type: SET_USER,
        payload: null,
      });
      Cookies.remove("leofranz");
      Cookies.remove("flyingorcha");
    }
  };

  const toggleSidebarHandle = () => {
    const newState = !isSidebarOpen;
    dispatch({
      type: SET_SIDEBAR,
      payload: newState,
    });
  };

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);
  return (
    <div className="w-full bg-slate-100 drop-shadow-md h-[60px] flex flex-col justify-center items-center z-20 absolute top-0 left-0">
      <div className="w-full px-4 flex flex-row justify-between items-center">
        <div className="flex flex-row justify-start items-center space-x-2">
          <img className="w-[35px]" src={Logo} alt="Logo" />
          <p className="text-2xl font-bold">Quamus</p>
          <button onClick={toggleSidebarHandle}>
            <GiHamburgerMenu className="text-lg text-black hover:text-slate-400" />
          </button>
        </div>
        <div className="flex flex-row justify-end items-center space-x-2">
          {user && <p className="text-slate-700 font-bold">{user.name}</p>}
          <div className="border-r border-slate-500 w-1 h-[25px]"></div>
          <button
            className="font-bold text-black hover:text-slate-400"
            onClick={logoutHandler}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

const maps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  isSidebarOpen: state.ui.isSidebarOpen,
});

export default connect(maps, {})(Navbar);
