import axios from "axios";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { ECOMMERCE_API_URL } from "../../../stores/actions/urls";
import Card from "../../core/ui/Card";
import Table from "../../core/ui/table/Table";
import Td from "../../core/ui/table/Td";

const AffiliateRecap = (props) => {
  const table = {
    width: [50, 150, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60],
    header: [
      "No",
      "Name",
      "Jan",
      "Peb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nop",
      "Dec",
    ],
  };
  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    setData();
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(
          `${ECOMMERCE_API_URL}/affiliate/closing/child-monthly-recap/`,
          config
        )
        .then((res) => {
          setData(res.data);
        });
    }
  }, []);
  useEffect(() => {
    loadData();
  }, []);
  return (
    <Card fullWidth={false}>
      <p className="text-center font-bold mb-2">Rekap Penjualan Affiliate</p>
      {data ? (
        <Table table={table}>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <Td>
                    <p className="text-center">{index + 1}</p>
                  </Td>
                  <Td>
                    <p>{item.name}</p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.num_sales[0]}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={""}
                      />
                    </p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.num_sales[1]}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={""}
                      />
                    </p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.num_sales[2]}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={""}
                      />
                    </p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.num_sales[3]}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={""}
                      />
                    </p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.num_sales[4]}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={""}
                      />
                    </p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.num_sales[5]}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={""}
                      />
                    </p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.num_sales[6]}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={""}
                      />
                    </p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.num_sales[7]}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={""}
                      />
                    </p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.num_sales[8]}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={""}
                      />
                    </p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.num_sales[9]}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={""}
                      />
                    </p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.num_sales[10]}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={""}
                      />
                    </p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.num_sales[11]}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={""}
                      />
                    </p>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <p className="text-sm">Loading...</p>
      )}
    </Card>
  );
};

export default AffiliateRecap;
