import React from "react";
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoPencil,
  IoTrash,
} from "react-icons/io5";
import NumberFormat from "react-number-format";
import Center from "../../../core/ui/table/Center";
import Table from "../../../core/ui/table/Table";
import Td from "../../../core/ui/table/Td";

const TierTable = (props) => {
  const { data, onEdit, onDelete } = props;
  const table = {
    width: [50, 150, 100, 80],
    header: ["No", "Name", "Fee", "Action"],
  };
  return (
    <Table table={table}>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index}>
              <Td>
                <p className="text-center">{index + 1}</p>
              </Td>
              <Td>
                <p>{item.name}</p>
              </Td>
              <Td>
                {item.type === "percentage" ? (
                  <p className="text-right">{item.fee} %</p>
                ) : (
                  <p className="text-right">
                    <NumberFormat
                      value={item.fee}
                      displayType={"text"}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      prefix={"Rp "}
                    />
                  </p>
                )}
              </Td>
              <Td>
                <Center>
                  <button
                    onClick={() => onEdit(item)}
                    className="border border-blue-700 p-0.5 rounded-lg"
                  >
                    <IoPencil className="text-blue-700" />
                  </button>
                </Center>
              </Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TierTable;
