import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "./Button";

const Sidebar = (props) => {
  const { affiliate, isSidebarOpen } = props;
  const [menu, setMenu] = useState();

  useEffect(() => {
    if (affiliate) {
      switch (affiliate.level) {
        case 1:
          setMenu(LEVEL1);
          break;
        case 2:
          setMenu(LEVEL2);
          break;
        default:
          break;
      }
    }
  }, [affiliate]);
  return (
    <div
      className={`${
        isSidebarOpen ? "w-[260px]" : "w-[80px]"
      } bg-white drop-shadow-md absolute top-0 left-0 z-10 pt-[70px] h-screen transition-all`}
    >
      {menu && (
        <div className="p-3 space-y-2">
          {menu.map((item, index) => {
            return (
              <Button key={index} isSidebarOpen={isSidebarOpen} item={item} />
            );
          })}
        </div>
      )}
    </div>
  );
};

const maps = (state) => ({
  isSidebarOpen: state.ui.isSidebarOpen,
  affiliate: state.auth.affiliate,
});

export default connect(maps, {})(Sidebar);

const LEVEL1 = [
  {
    link: "dashboard",
    title: "Dashboard",
  },
  {
    link: "verified-user",
    title: "Verified User",
  },
  {
    link: "unpaid-order",
    title: "Unpaid Order",
  },
  {
    link: "sales",
    title: "Sales",
  },
  {
    link: "product",
    title: "Product",
  },
  {
    link: "track",
    title: "Ads Track",
  },
  {
    link: "generate",
    title: "Generate Link",
  },
  {
    link: "tier",
    title: "Tier Affiliate",
  },
  {
    link: "affiliate",
    title: "Affiliate",
  },
  {
    link: "payment-history",
    title: "Payment History",
  },
  {
    link: "setting",
    title: "Setting",
  },
];

const LEVEL2 = [
  {
    link: "dashboard",
    title: "Dashboard",
  },
  {
    link: "unpaid-order",
    title: "Unpaid Order",
  },
  {
    link: "sales",
    title: "Sales",
  },
  {
    link: "product",
    title: "Product",
  },
  {
    link: "track",
    title: "Ads Track",
  },
  {
    link: "generate",
    title: "Generate Link",
  },
  {
    link: "payment-history",
    title: "Payment History",
  },
  {
    link: "setting",
    title: "Setting",
  },
];
