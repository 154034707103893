import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/core/ui/Layout";
import { ECOMMERCE_API_URL } from "../../stores/actions/urls";
import axios from "axios";
import Cookies from "js-cookie";
import Card from "../../components/core/ui/Card";
import ProductTable from "../../components/page/ecommerce/product/Table";
import { toast } from "wc-toast";

const Product = (props) => {
  const { affiliate, isAuthenticated } = props;
  const [data, setData] = useState();
  const navigate = useNavigate();
  const loadData = useCallback(async () => {
    setData();
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios.get(`${ECOMMERCE_API_URL}/v2/product`, config).then((res) => {
        setData(res.data);
      });
    }
  }, []);
  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);
  useEffect(() => {
    loadData();
    document.title = "Product | Affiliate Quamus";
  }, []);
  return (
    <Fragment>
      <Layout title="Product">
        {data ? (
          <Card>
            <ProductTable data={data} />
          </Card>
        ) : (
          <p className="text-sm">Loading...</p>
        )}
      </Layout>
    </Fragment>
  );
};

const maps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  affiliate: state.auth.affiliate,
});

export default connect(maps, {})(Product);
