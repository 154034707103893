import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Card from "../../components/core/ui/Card";
import Layout from "../../components/core/ui/Layout";

import { getOwnedVerifiedUser } from "../../stores/actions/auth";
import Select from "../../components/core/ui/form/Select";
import UserTable from "../../components/page/auth/verifiedUser/Table";

const VerifiedUser = (props) => {
  const { getOwnedVerifiedUser } = props;
  const [data, setData] = useState();
  const monthList = [
    { value: 1, label: "January" },
    { value: 2, label: "Pebruary" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const yearList = [
    {
      value: parseInt(moment().format("YYYY")) - 1,
      label: (parseInt(moment().format("YYYY")) - 1).toString(),
    },
    {
      value: parseInt(moment().format("YYYY")),
      label: parseInt(moment().format("YYYY")).toString(),
    },
  ];
  const [selectedMonth, setSelectedMonth] = useState(moment().format("M"));
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));

  const changeMonthHandler = (e) => {
    setSelectedMonth(e.target.value);
  };
  const changeYearHandler = (e) => {
    setSelectedYear(e.target.value);
  };

  useEffect(() => {
    setData();
    if (selectedMonth && selectedYear) {
      getOwnedVerifiedUser(selectedMonth, selectedYear, setData);
    }
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    document.title = "Verified User | Affiliate Quamus";
  }, []);
  return (
    <Layout title="Verified User">
      <Card>
        <div className="w-full bg-slate-200 mb-2 py-2 px-4 space-x-1 rounded-md drop-shadow-sm flex justify-end items-center">
          <div className="w-[150px]">
            <Select
              list={monthList}
              provideNull={false}
              title="Month"
              name="month_id"
              value={selectedMonth}
              onChange={changeMonthHandler}
            />
          </div>
          <div className="w-[80px]">
            <Select
              list={yearList}
              provideNull={false}
              title="Year"
              name="year_id"
              value={selectedYear}
              onChange={changeYearHandler}
            />
          </div>
        </div>
        {data ? (
          <Fragment>
            <p className="text-sm mb-1">Jumlah Data: {data.length}</p>
            <UserTable data={data} />
          </Fragment>
        ) : (
          <p className="text-sm">Loading...</p>
        )}
      </Card>
    </Layout>
  );
};

export default connect(null, { getOwnedVerifiedUser })(VerifiedUser);
