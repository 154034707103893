import React, { useCallback, useEffect, useState } from "react";
import Checkbox from "../../../core/ui/form/Checkbox";
import Input from "../../../core/ui/form/Input";
import InputNumeric from "../../../core/ui/form/InputNumeric";
import Select from "../../../core/ui/form/Select";
import Panel from "../../../core/ui/popup/Panel";
import validator from "validator";

const TierForm = (props) => {
  const typeList = [
    {
      value: "percentage",
      label: "percentage",
    },
    {
      value: "amount",
      label: "amount",
    },
  ];
  const { width, item, title, isSending, onClose, onSubmit, setInputData } =
    props;
  const [isValid, setIsValid] = useState(true);
  const [formData, setFormData] = useState({
    _id: "-1",
    name: "",
    type: "percentage",
    fee: "",
  });
  const changeHandler = (e) => {
    if (e.target) {
      if (e.target.type === "checkbox") {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    }
  };

  useEffect(() => {
    if (isSending) {
      setIsValid(false);
      setInputData();
    } else {
      if (formData.name.length > 0 && formData.fee.length > 0) {
        setIsValid(true);
        setInputData(formData);
      } else {
        setIsValid(false);
        setInputData();
      }
    }
  }, [formData, isSending]);

  useEffect(() => {
    if (item) {
      setFormData({
        _id: item._id.$oid,
        name: item.name,
        type: item.type,
        fee: String(item.fee),
      });
    }
  }, [item]);

  return (
    <Panel
      title={title}
      onClose={onClose}
      onSubmit={onSubmit}
      isValid={isValid}
      isSending={isSending}
    >
      <div style={{ width: `${width}px` }} className="grid grid-cols-12 gap-3">
        <div className="col-span-12">
          <Input
            title="name"
            name="name"
            type="text"
            value={formData.name}
            onChange={changeHandler}
          />
        </div>
        <div className="col-span-6">
          <Select
            list={typeList}
            provideNull={false}
            title="type"
            name="type"
            type="text"
            value={formData.type}
            onChange={changeHandler}
          />
        </div>
        <div className="col-span-6">
          <InputNumeric
            title="fee"
            name="fee"
            value={formData.fee}
            onChange={changeHandler}
          />
        </div>
      </div>
    </Panel>
  );
};

export default TierForm;
