import React, { useEffect, useState } from "react";
import Layout from "../../components/core/ui/Layout";
import Cookies from "js-cookie";
import axios from "axios";
import { ECOMMERCE_API_URL } from "../../stores/actions/urls";
import Card from "../../components/core/ui/Card";
import OrderTable from "../../components/page/ecommerce/unpaid_order/Table";

const UnpaidOrder = (props) => {
  const [data, setData] = useState();

  const loadData = async () => {
    setData();
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(`${ECOMMERCE_API_URL}/v2/affiliate/unpaid-order`, config)
        .then((res) => {
          setData(res.data);
        });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Layout title="Unpaid Order">
      <Card>
        {data ? (
          <OrderTable data={data} />
        ) : (
          <p className="text-sm">Loading...</p>
        )}
      </Card>
    </Layout>
  );
};

export default UnpaidOrder;
