import React, { useCallback, useEffect, useState } from "react";
import Checkbox from "../../../core/ui/form/Checkbox";
import Input from "../../../core/ui/form/Input";
import InputNumeric from "../../../core/ui/form/InputNumeric";
import Select from "../../../core/ui/form/Select";
import Panel from "../../../core/ui/popup/Panel";
import validator from "validator";
import Cookies from "js-cookie";
import axios from "axios";
import { ECOMMERCE_API_URL } from "../../../../stores/actions/urls";

const AffiliateForm = (props) => {
  const [tierList, setTierList] = useState();
  const { width, item, title, isSending, onClose, onSubmit, setInputData } =
    props;
  const [isValid, setIsValid] = useState(true);
  const [formData, setFormData] = useState({
    _id: "-1",
    email: "",
    tier_id: "-1",
    is_active: true,
  });
  const changeHandler = (e) => {
    if (e.target) {
      if (e.target.type === "checkbox") {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    }
  };

  const loadTierList = useCallback(async () => {
    if (Cookies.get("leofranz")) {
      const list = [];
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(`${ECOMMERCE_API_URL}/affiliate/tier/`, config)
        .then((res) => {
          res.data.map((item, index) => {
            list.push({
              value: item._id.$oid,
              label: item.name,
            });
          });
          setTierList(list);
        });
    }
  }, []);

  useEffect(() => {
    if (isSending) {
      setIsValid(false);
      setInputData();
    } else {
      if (validator.isEmail(formData.email) && formData.tier_id !== "-1") {
        setIsValid(true);
        setInputData(formData);
      } else {
        setIsValid(false);
        setInputData();
      }
    }
  }, [formData, isSending]);

  useEffect(() => {
    if (item) {
      setFormData({
        _id: item._id.$oid,
        email: item.email,
        tier_id: item.tier_id.$oid,
        is_active: true,
      });
    }
  }, [item]);

  useEffect(() => {
    loadTierList();
  }, []);

  return (
    <Panel
      title={title}
      onClose={onClose}
      onSubmit={onSubmit}
      isValid={isValid}
      isSending={isSending}
    >
      <div style={{ width: `${width}px` }} className="grid grid-cols-12 gap-3">
        <div className="col-span-12">
          <Input
            disabled={item ? true : false}
            title="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={changeHandler}
          />
        </div>
        {tierList && (
          <div className="col-span-8">
            <Select
              list={tierList}
              provideNull={false}
              title="Tier"
              name="tier_id"
              value={formData.tier_id}
              onChange={changeHandler}
            />
          </div>
        )}
        <div className="col-span-4">
          <Checkbox
            title="Is Active"
            name="is_active"
            value={formData.is_active}
            onChange={changeHandler}
          />
        </div>
      </div>
    </Panel>
  );
};

export default AffiliateForm;
