import React from "react";

const Select2 = (props) => {
  const { provideNull, value, name, list, onChange } = props;
  return (
    <div>
      <select
        className="w-full outline-none border border-slate-300 p-1 font-bold text-sm"
        name={name}
        value={value}
        onChange={onChange}
      >
        {provideNull ? (
          <option value={"-1"}>-</option>
        ) : (
          <option value={"-1"} disabled></option>
        )}

        {list.map((item, index) => {
          return (
            <option className="" key={index} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select2;
