import React from "react";
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoPencil,
} from "react-icons/io5";
import NumberFormat from "react-number-format";
import Center from "../../../core/ui/table/Center";
import Table from "../../../core/ui/table/Table";
import Td from "../../../core/ui/table/Td";
import moment from "moment";

const UserTable = (props) => {
  const { data, onEdit } = props;
  const table = {
    width: [50, 120, 300],
    header: ["No", "Register Date", "User"],
  };
  return (
    <Table table={table}>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index}>
              <Td>
                <p className="text-center">{index + 1}</p>
              </Td>
              <Td>
                <p className="text-center">
                  {moment(item.created_at).format("D-M-YYYY")}
                </p>
              </Td>
              <Td>
                <p className="font-bold text-blue-400">{item.name}</p>
                <p className="text-slate-500 text-xs">{item.phone}</p>
                <p className="text-slate-500 text-xs">{item.email}</p>
              </Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default UserTable;
