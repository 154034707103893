import React from "react";
import { IoAdd } from "react-icons/io5";

const AddButton = (props) => {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className="bg-primary-200 hover:bg-primary-100 text-white px-3 py-1 rounded-md text-sm"
    >
      <IoAdd className="inline" />
      <p className="inline">Add</p>
    </button>
  );
};

export default AddButton;
