import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { loadUser, loadAffiliate } from "../stores/actions/auth";

const Layout = (props) => {
  const { user, loadUser, loadAffiliate } = props;
  const { pathname } = useLocation();
  useEffect(() => {
    loadUser();
    window.scrollTo(0, 0);
    document.body.style.overflow = "unset";
  }, [pathname]);

  useEffect(() => {
    if (user) {
      loadAffiliate(user.email);
    }
  }, [user, pathname]);
  return (
    <Fragment>
      <wc-toast position="bottom-right"></wc-toast>
      {props.children}
    </Fragment>
  );
};

const maps = (state) => ({
  user: state.auth.user,
});

export default connect(maps, { loadUser, loadAffiliate })(Layout);
