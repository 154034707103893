import axios from "axios";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { ECOMMERCE_API_URL } from "../../../../../stores/actions/urls";
import Card from "../../../../core/ui/Card";
import Select from "../../../../core/ui/form/Select";
import Td from "../../../../core/ui/table/Td";
import moment from "moment";
import Table from "../../../../core/ui/table/Table";

const PaymentAffiliate = (props) => {
  const table = {
    width: [50, 110, 140],
    header: ["No", "Date", "Amount"],
  };
  const [affiliateList, setAffiliateList] = useState();
  const [selectedAffiliate, setSelectedAffiliate] = useState();
  const [data, setData] = useState();
  const loadAffiliate = useCallback(async () => {
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(`${ECOMMERCE_API_URL}/affiliate-child/`, config)
        .then((res) => {
          const affData = [];
          res.data.map((item) => {
            affData.push({ value: item._id.$oid, label: item.name });
          });
          setAffiliateList(affData);
          setSelectedAffiliate(affData[0].value);
        });
    }
  }, []);
  const changeAffiliateHandler = (e) => {
    setSelectedAffiliate(e.target.value);
  };
  const loadData = async () => {
    setData();
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(
          `${ECOMMERCE_API_URL}/affiliate/payment/${selectedAffiliate}`,
          config
        )
        .then((res) => {
          setData(res.data);
        });
    }
  };
  useEffect(() => {
    if (selectedAffiliate) {
      loadData();
    }
  }, [selectedAffiliate]);
  useEffect(() => {
    loadAffiliate();
  }, []);
  return (
    <Card>
      <div className="w-full bg-slate-200 mb-2 py-2 px-4 space-x-1 rounded-md drop-shadow-sm flex justify-end items-center">
        {affiliateList ? (
          <div className="w-[200px]">
            <Select
              list={affiliateList}
              provideNull={false}
              title="Affiliate"
              name="affiliate_id"
              value={selectedAffiliate}
              onChange={changeAffiliateHandler}
            />
          </div>
        ) : (
          <p className="text-sm">Loading...</p>
        )}
      </div>
      {data ? (
        <Table table={table}>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <Td>
                    <p className="text-center">{index + 1}</p>
                  </Td>
                  <Td>
                    <p className="text-center">
                      {moment(item.created_at.$date).format("D-M-YYYY")}
                    </p>
                  </Td>
                  <Td>
                    <p className="text-right">
                      <NumberFormat
                        value={item.amount}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={"Rp "}
                      />
                    </p>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <p className="text-sm">Loading...</p>
      )}
    </Card>
  );
};

export default PaymentAffiliate;
