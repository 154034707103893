import axios from "axios";
import Cookies from "js-cookie";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../components/core/ui/Layout";
import AffiliateRecap from "../components/page/dashboard/AffiliateRecap";
import Balance from "../components/page/dashboard/Balance";
import ChartSale from "../components/page/dashboard/ChartSale";
import { ECOMMERCE_API_URL } from "../stores/actions/urls";

const Home = (props) => {
  const { isAuthenticated, affiliate } = props;
  const [balance, setBalance] = useState();
  const navigate = useNavigate();
  const loadBalance = useCallback(async () => {
    setBalance();
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(`${ECOMMERCE_API_URL}/affiliate/balance-detail/`, config)
        .then((res) => {
          setBalance(res.data.balance);
        });
    }
  }, []);
  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [isAuthenticated]);
  useEffect(() => {
    loadBalance();
    document.title = "Dashboard | Affiliate Quamus";
  }, []);

  return (
    <Layout title="Dashboard">
      <p className="mb-2">
        Selamat datang di Dashboard Affiliate{" "}
        <span className="text-primary-200 font-bold">Quamus ID</span>
        {balance && (
          <span>
            , Saldo anda{" "}
            <span className="font-bold">
              <NumberFormat
                value={balance}
                displayType={"text"}
                decimalSeparator={","}
                thousandSeparator={"."}
                prefix={"Rp "}
              />
            </span>
          </span>
        )}
      </p>
      <div className="grid grid-cols-12">
        <div className="col-span-8">
          <ChartSale />
        </div>
        {affiliate && affiliate.level === 1 && (
          <Fragment>
            <div className="col-span-12">
              <Balance />
            </div>
            <div className="col-span-12">
              <AffiliateRecap />
            </div>
          </Fragment>
        )}
      </div>
    </Layout>
  );
};

const maps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  affiliate: state.auth.affiliate,
});

export default connect(maps, {})(Home);
