import { SET_AUTHENTICATED, SET_USER, SET_AFFILIATE } from "../actions/types";

const initialState = {
  isAuthenticated: null,
  user: null,
  affiliate: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: payload,
      };
    case SET_USER:
      return {
        ...state,
        user: payload,
      };
    case SET_AFFILIATE:
      return {
        ...state,
        affiliate: payload,
      };
    default:
      return state;
  }
}
