import axios from "axios";
import Cookies from "js-cookie";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { IoSendSharp } from "react-icons/io5";
import NumberFormat from "react-number-format";
import { ECOMMERCE_API_URL } from "../../../stores/actions/urls";
import Card from "../../core/ui/Card";
import Center from "../../core/ui/table/Center";
import Table from "../../core/ui/table/Table";
import Td from "../../core/ui/table/Td";
import BalanceForm from "./BalanceForm";
import { toast } from "wc-toast";

const Balance = (props) => {
  const [selectedId, setSelectedId] = useState("");
  const [isFormShow, setIsFormShow] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [formItem, setFormItem] = useState();
  const [inputData, setInputData] = useState();
  const table = {
    width: [50, 150, 150, 120, 120, 80],
    header: ["No", "Name", "Email", "Phone", "Balance", "Action"],
  };
  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    setData();
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(`${ECOMMERCE_API_URL}/affiliate/balance/`, config)
        .then((res) => {
          setData(res.data);
        });
    }
  }, []);
  const showAddFormHandler = (item) => {
    setSelectedId(item._id.$oid);
    setFormItem(item);
    setFormTitle("Add Payment");
    setIsFormShow(true);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    storeData();
  };
  const storeData = async () => {
    setIsSending(true);
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      const body = JSON.stringify({
        date: inputData.date,
        amount: parseInt(inputData.amount.replace(".", "")),
      });
      await axios
        .post(
          `${ECOMMERCE_API_URL}/affiliate/payment/${selectedId}`,
          body,
          config
        )
        .then((res) => {
          setIsFormShow(false);
          setIsSending(false);
          loadData();
          toast.success("Data inserted successfully!");
        })
        .catch((e) => {
          setIsFormShow(false);
          setIsSending(false);
          toast.error("Data inserted failed!");
        });
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <Fragment>
      {isFormShow && (
        <BalanceForm
          width={300}
          item={formItem}
          title={formTitle}
          isSending={isSending}
          onClose={() => setIsFormShow(false)}
          onSubmit={formSubmitHandler}
          setInputData={setInputData}
        />
      )}
      <Card fullWidth={false}>
        <p className="text-center font-bold mb-2">
          Saldo Fee Penjualan Affiliate
        </p>
        {data ? (
          <Table table={table}>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index}>
                    <Td>
                      <p className="text-center">{index + 1}</p>
                    </Td>
                    <Td>
                      <p>{item.name}</p>
                    </Td>
                    <Td>
                      <p>{item.email}</p>
                    </Td>
                    <Td>
                      <p className="text-center">{item.phone}</p>
                    </Td>
                    <Td>
                      <p className="text-right">
                        <NumberFormat
                          value={item.balance}
                          displayType={"text"}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          prefix={"Rp "}
                        />
                      </p>
                    </Td>
                    <Td>
                      <Center>
                        <button
                          onClick={() => showAddFormHandler(item)}
                          className="border border-blue-700 p-0.5 rounded-lg"
                        >
                          <IoSendSharp className="text-blue-700" />
                        </button>
                      </Center>
                    </Td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <p>Loading...</p>
        )}
      </Card>
    </Fragment>
  );
};

export default Balance;
