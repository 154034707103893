import React from "react";
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoPencil,
} from "react-icons/io5";
import NumberFormat from "react-number-format";
import Center from "../../../core/ui/table/Center";
import Table from "../../../core/ui/table/Table";
import Td from "../../../core/ui/table/Td";

const AffiliateTable = (props) => {
  const { data, onEdit } = props;
  const table = {
    width: [50, 150, 80, 180, 110, 110, 110, 80, 80],
    header: [
      "No",
      "Name",
      "Code",
      "Email",
      "Phone",
      "Tier",
      "Fee",
      "Active",
      "Action",
    ],
  };
  return (
    <Table table={table}>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index}>
              <Td>
                <p className="text-center">{index + 1}</p>
              </Td>
              <Td>
                <p>{item.name}</p>
              </Td>
              <Td>
                <p className="text-center text-xs font-bold">{item.code}</p>
              </Td>
              <Td>
                <p>{item.email}</p>
              </Td>
              <Td>
                <p className="text-center">{item.phone}</p>
              </Td>
              <Td>
                <p className="text-center">{item.tier_name}</p>
              </Td>
              <Td>
                {item.type === "percentage" ? (
                  <p className="text-right">{item.fee} %</p>
                ) : (
                  <p className="text-right">
                    <NumberFormat
                      value={item.fee}
                      displayType={"text"}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      prefix={"Rp "}
                    />
                  </p>
                )}
              </Td>
              <Td>
                <Center>
                  {item.is_active ? (
                    <IoCheckmarkCircleOutline className="text-xl text-green-700" />
                  ) : (
                    <IoCloseCircleOutline className="text-xl text-red-700" />
                  )}
                </Center>
              </Td>
              <Td>
                <Center>
                  <button
                    onClick={() => onEdit(item)}
                    className="border border-blue-700 p-0.5 rounded-lg"
                  >
                    <IoPencil className="text-blue-700" />
                  </button>
                </Center>
              </Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default AffiliateTable;
