import React from "react";
import Header from "./Header";

const Table = (props) => {
  const { table } = props;
  return (
    <div className="overflow-x-auto border-2 border-slate-200 pb-3">
      <table
        className={`w-px border-collapse border border-slate-400 table-fixed text-sm`}
      >
        <Header table={table} />
        {props.children}
      </table>
    </div>
  );
};

export default Table;
