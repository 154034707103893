import React, { Fragment, useCallback, useEffect, useState } from "react";
import Card from "../../components/core/ui/Card";
import Select from "../../components/core/ui/form/Select";
import Layout from "../../components/core/ui/Layout";
import moment from "moment";
import Cookies from "js-cookie";
import axios from "axios";
import { ECOMMERCE_API_URL } from "../../stores/actions/urls";
import SalesTable from "../../components/page/ecommerce/sales/Table";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import _, { filter } from "lodash";

const Sales = (props) => {
  const navigate = useNavigate();
  const { isAuthenticated, affiliate } = props;
  const monthList = [
    { value: 1, label: "January" },
    { value: 2, label: "Pebruary" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const yearList = [
    {
      value: parseInt(moment().format("YYYY")) - 1,
      label: (parseInt(moment().format("YYYY")) - 1).toString(),
    },
    {
      value: parseInt(moment().format("YYYY")),
      label: parseInt(moment().format("YYYY")).toString(),
    },
  ];
  const [selectedMonth, setSelectedMonth] = useState(moment().format("M"));
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [utmSourceList, setUtmSourceList] = useState([]);
  const [utmMediumList, setUtmMediumList] = useState([]);
  const [utmCampaignList, setUtmCampaignList] = useState([]);
  const [utmTermList, setUtmTermList] = useState([]);
  const [utmContentList, setUtmContentList] = useState([]);
  const [selectedPage, setSelectedPage] = useState("/landing");
  const [selectedUtmSource, setSelectedUtmSource] = useState("-");
  const [selectedUtmMedium, setSelectedUtmMedium] = useState("-");
  const [selectedUtmCampaign, setSelectedUtmCampaign] = useState("-");
  const [selectedUtmTerm, setSelectedUtmTerm] = useState("-");
  const [selectedUtmContent, setSelectedUtmContent] = useState("-");
  const [data, setData] = useState();
  const [filterData, setFilterData] = useState();
  const changeMonthHandler = (e) => {
    setSelectedMonth(e.target.value);
  };
  const changeYearHandler = (e) => {
    setSelectedYear(e.target.value);
  };
  const changeHandler = (e) => {
    switch (e.target.name) {
      case "page":
        setSelectedPage(e.target.value);
        break;
      case "utm_source":
        setSelectedUtmSource(e.target.value);
        break;
      case "utm_medium":
        setSelectedUtmMedium(e.target.value);
        break;
      case "utm_campaign":
        setSelectedUtmCampaign(e.target.value);
        break;
      case "utm_term":
        setSelectedUtmTerm(e.target.value);
        break;
      case "utm_content":
        setSelectedUtmContent(e.target.value);
        break;
      default:
        break;
    }
  };

  const loadData = useCallback(async (month, year) => {
    setData();
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(
          `${ECOMMERCE_API_URL}/v2/affiliate/closing/${month}/${year}`,
          config
        )
        .then((res) => {
          setData(res.data);
        });
    }
  }, []);

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      loadData(selectedMonth, selectedYear);
    }
  }, [selectedMonth, selectedYear]);
  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);
  useEffect(() => {
    document.title = "Sales | Affiliate Quamus";
  }, []);

  useEffect(() => {
    setUtmSourceList([]);
    setUtmMediumList([]);
    setUtmCampaignList([]);
    setUtmTermList([]);
    setUtmContentList([]);
    if (affiliate) {
      setUtmSourceList((oldArray) => [...oldArray, { value: "-", label: "-" }]);
      if (affiliate.utm_source) {
        affiliate.utm_source.map((item, index) => {
          setUtmSourceList((oldArray) => [
            ...oldArray,
            { value: item, label: item },
          ]);
          return null;
        });
      }
      setUtmMediumList((oldArray) => [...oldArray, { value: "-", label: "-" }]);
      if (affiliate.utm_medium) {
        affiliate.utm_medium.map((item, index) => {
          setUtmMediumList((oldArray) => [
            ...oldArray,
            { value: item, label: item },
          ]);
          return null;
        });
      }
      setUtmCampaignList((oldArray) => [
        ...oldArray,
        { value: "-", label: "-" },
      ]);
      if (affiliate.utm_campaign) {
        affiliate.utm_campaign.map((item, index) => {
          setUtmCampaignList((oldArray) => [
            ...oldArray,
            { value: item, label: item },
          ]);
          return null;
        });
      }
      setUtmTermList((oldArray) => [...oldArray, { value: "-", label: "-" }]);
      if (affiliate.utm_term) {
        affiliate.utm_term.map((item, index) => {
          setUtmTermList((oldArray) => [
            ...oldArray,
            { value: item, label: item },
          ]);
          return null;
        });
      }
      setUtmContentList((oldArray) => [
        ...oldArray,
        { value: "-", label: "-" },
      ]);
      if (affiliate.utm_content) {
        affiliate.utm_content.map((item, index) => {
          setUtmContentList((oldArray) => [
            ...oldArray,
            { value: item, label: item },
          ]);
          return null;
        });
      }
    }
  }, [affiliate]);

  useEffect(() => {
    if (data) {
      const raw = [];
      data.map((item, index) => {
        let is_utm_source_fit = true;
        if (selectedUtmSource !== "-") {
          if (selectedUtmSource !== item.affiliate.utm_source) {
            is_utm_source_fit = false;
          }
        }
        let is_utm_medium_fit = true;
        if (selectedUtmMedium !== "-") {
          if (selectedUtmMedium !== item.affiliate.utm_medium) {
            is_utm_medium_fit = false;
          }
        }
        let is_utm_campaign_fit = true;
        if (selectedUtmCampaign !== "-") {
          if (selectedUtmCampaign !== item.affiliate.utm_campaign) {
            is_utm_campaign_fit = false;
          }
        }
        let is_utm_term_fit = true;
        if (selectedUtmTerm !== "-") {
          if (selectedUtmTerm !== item.affiliate.utm_term) {
            is_utm_term_fit = false;
          }
        }
        let is_utm_content_fit = true;
        if (selectedUtmContent !== "-") {
          if (selectedUtmContent !== item.affiliate.utm_content) {
            is_utm_content_fit = false;
          }
        }
        if (
          is_utm_source_fit &&
          is_utm_medium_fit &&
          is_utm_campaign_fit &&
          is_utm_term_fit &&
          is_utm_content_fit
        ) {
          raw.push(item);
        }
      });
      setFilterData(raw);
    }
  }, [
    data,
    selectedUtmSource,
    selectedUtmMedium,
    selectedUtmCampaign,
    selectedUtmTerm,
    selectedUtmContent,
  ]);

  return (
    <Layout title="Sales">
      <Card>
        <div className="w-full bg-slate-200 mb-2 py-2 px-4 space-x-1 rounded-md drop-shadow-sm flex justify-end items-center">
          {utmSourceList.length > 1 && (
            <div className="w-[120px]">
              <Select
                list={utmSourceList}
                provideNull={false}
                title="utm_source"
                name="utm_source"
                value={selectedUtmSource}
                onChange={changeHandler}
              />
            </div>
          )}
          {utmMediumList.length > 1 && (
            <div className="w-[120px]">
              <Select
                list={utmMediumList}
                provideNull={false}
                title="utm_medium"
                name="utm_medium"
                value={selectedUtmMedium}
                onChange={changeHandler}
              />
            </div>
          )}
          {utmCampaignList.length > 1 && (
            <div className="w-[120px]">
              <Select
                list={utmCampaignList}
                provideNull={false}
                title="utm_campaign"
                name="utm_campaign"
                value={selectedUtmCampaign}
                onChange={changeHandler}
              />
            </div>
          )}
          {utmTermList.length > 1 && (
            <div className="w-[120px]">
              <Select
                list={utmTermList}
                provideNull={false}
                title="utm_term"
                name="utm_term"
                value={selectedUtmTerm}
                onChange={changeHandler}
              />
            </div>
          )}
          {utmContentList.length > 1 && (
            <div className="w-[120px]">
              <Select
                list={utmContentList}
                provideNull={false}
                title="utm_content"
                name="utm_content"
                value={selectedUtmContent}
                onChange={changeHandler}
              />
            </div>
          )}
          <div className="w-[150px]">
            <Select
              list={monthList}
              provideNull={false}
              title="Month"
              name="month_id"
              value={selectedMonth}
              onChange={changeMonthHandler}
            />
          </div>
          <div className="w-[80px]">
            <Select
              list={yearList}
              provideNull={false}
              title="Year"
              name="year_id"
              value={selectedYear}
              onChange={changeYearHandler}
            />
          </div>
        </div>
        {filterData ? (
          <Fragment>
            <p className="text-sm mb-1">Jumlah Data: {filterData.length}</p>
            <SalesTable data={filterData} />
          </Fragment>
        ) : (
          <p className="text-sm">Loading...</p>
        )}
      </Card>
    </Layout>
  );
};

const maps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  affiliate: state.auth.affiliate,
});

export default connect(maps, {})(Sales);
