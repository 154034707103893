import React from "react";

const Card = (props) => {
  const { fullWidth } = props;
  return (
    <div
      className={`bg-white p-4 mb-4 drop-shadow-md rounded-lg ${
        fullWidth ? "block" : "inline-block"
      } max-w-full`}
    >
      {props.children}
    </div>
  );
};

export default Card;
