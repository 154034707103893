import React, { Fragment } from "react";
import { IoClose, IoSave } from "react-icons/io5";

const Footer = (props) => {
  const { isValid, isSending, onClose } = props;
  return (
    <div className="flex justify-end items-center space-x-2">
      {!isSending && (
        <button
          onClick={onClose}
          type="button"
          className="flex justify-center items-center space-x-1 bg-red-500 hover:bg-red-400 text-white py-1 px-2 rounded-md text-sm"
        >
          <IoClose />
          <p>Cancel</p>
        </button>
      )}
      <button
        disabled={!isValid}
        type="submit"
        className="flex justify-center items-center space-x-1 bg-primary-200 hover:bg-primary-100 text-white py-1 px-2 rounded-md disabled:bg-gray-400 text-sm"
      >
        {isSending ? (
          <div>
            <div
              className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full mr-2"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
            <p className="inline">Saving...</p>
          </div>
        ) : (
          <Fragment>
            <IoSave />
            <p className="inline">Save</p>
          </Fragment>
        )}
      </button>
    </div>
  );
};

export default Footer;
