import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/core/ui/Layout";
import { ECOMMERCE_API_URL } from "../../stores/actions/urls";
import axios from "axios";
import Cookies from "js-cookie";
import Card from "../../components/core/ui/Card";
import AddButton from "../../components/core/ui/table/AddButton";
import { toast } from "wc-toast";
import AffiliateTable from "../../components/page/ecommerce/affiliate/Table";
import AffiliateForm from "../../components/page/ecommerce/affiliate/Form";
import _ from "lodash";

const Affiliate = (props) => {
  const { isAuthenticated } = props;
  const [data, setData] = useState();
  const [isFormShow, setIsFormShow] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [formItem, setFormItem] = useState();
  const [inputData, setInputData] = useState();
  const [inputFile, setInputFile] = useState();
  const navigate = useNavigate();
  const loadData = useCallback(async () => {
    setData();
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(`${ECOMMERCE_API_URL}/affiliate-child/`, config)
        .then((res) => {
          setData(res.data);
        });
    }
  }, []);
  const showAddFormHandler = () => {
    setFormItem();
    setFormTitle("Add Affiliate");
    setIsFormShow(true);
  };
  const showEditFormHandler = (item) => {
    setFormItem(item);
    setFormTitle("Update Affiliate");
    setIsFormShow(true);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    storeData();
  };
  const storeData = async () => {
    setIsSending(true);
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      const body = JSON.stringify({
        _id: inputData._id,
        email: inputData.email,
        tier_id: inputData.tier_id,
        is_active: inputData.is_active,
      });
      await axios
        .post(`${ECOMMERCE_API_URL}/affiliate-child/`, body, config)
        .then((res) => {
          setIsFormShow(false);
          setIsSending(false);
          loadData();
          toast.success("Data inserted successfully!");
        })
        .catch((e) => {
          setIsFormShow(false);
          setIsSending(false);
          toast.error(e.response.data.error);
        });
    }
  };
  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);
  useEffect(() => {
    loadData();
    document.title = "Affiliate | Affiliate Quamus";
  }, []);

  return (
    <Fragment>
      {isFormShow && (
        <AffiliateForm
          width={300}
          item={formItem}
          title={formTitle}
          isSending={isSending}
          onClose={() => setIsFormShow(false)}
          onSubmit={formSubmitHandler}
          setInputData={setInputData}
        />
      )}
      <Layout title="Affiliate">
        {data ? (
          <Card>
            <div className="w-full bg-slate-200 mb-2 py-2 px-4 rounded-md drop-shadow-sm flex justify-end items-center">
              <AddButton onClick={() => showAddFormHandler()} />
            </div>
            <AffiliateTable data={data} onEdit={showEditFormHandler} />
          </Card>
        ) : (
          <p className="text-sm">Loading...</p>
        )}
      </Layout>
    </Fragment>
  );
};

const maps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(maps, {})(Affiliate);
