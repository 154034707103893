import React, { useEffect, useState } from "react";
import Card from "../../components/core/ui/Card";
import Layout from "../../components/core/ui/Layout";
import Select2 from "../../components/core/ui/form/Select2";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoCopy } from "react-icons/io5";
import { toast } from "wc-toast";
import { CURRENT_URL } from "../../stores/actions/urls";

const GenerateLink = (props) => {
  const { affiliate } = props;
  const pageList = [
    { value: "/landing", label: "Landing Page" },
    { value: "/checkout-subscribe", label: "Checkout Page" },
    { value: "/daftar-form", label: "Checkout Form" },
    { value: "/daftar", label: "User Registration" },
  ];
  const [link, setLink] = useState("");
  const [utmSourceList, setUtmSourceList] = useState([]);
  const [utmMediumList, setUtmMediumList] = useState([]);
  const [utmCampaignList, setUtmCampaignList] = useState([]);
  const [utmTermList, setUtmTermList] = useState([]);
  const [utmContentList, setUtmContentList] = useState([]);
  const [selectedPage, setSelectedPage] = useState("/landing");
  const [selectedUtmSource, setSelectedUtmSource] = useState("-");
  const [selectedUtmMedium, setSelectedUtmMedium] = useState("-");
  const [selectedUtmCampaign, setSelectedUtmCampaign] = useState("-");
  const [selectedUtmTerm, setSelectedUtmTerm] = useState("-");
  const [selectedUtmContent, setSelectedUtmContent] = useState("-");
  const changeHandler = (e) => {
    switch (e.target.name) {
      case "page":
        setSelectedPage(e.target.value);
        break;
      case "utm_source":
        setSelectedUtmSource(e.target.value);
        break;
      case "utm_medium":
        setSelectedUtmMedium(e.target.value);
        break;
      case "utm_campaign":
        setSelectedUtmCampaign(e.target.value);
        break;
      case "utm_term":
        setSelectedUtmTerm(e.target.value);
        break;
      case "utm_content":
        setSelectedUtmContent(e.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.title = "Generate Link | Affiliate Quamus";
  }, []);
  useEffect(() => {
    setUtmSourceList([]);
    setUtmMediumList([]);
    setUtmCampaignList([]);
    setUtmTermList([]);
    setUtmContentList([]);
    if (affiliate) {
      setUtmSourceList((oldArray) => [...oldArray, { value: "-", label: "-" }]);
      if (affiliate.utm_source) {
        affiliate.utm_source.map((item, index) => {
          setUtmSourceList((oldArray) => [
            ...oldArray,
            { value: item, label: item },
          ]);
          return null;
        });
      }
      setUtmMediumList((oldArray) => [...oldArray, { value: "-", label: "-" }]);
      if (affiliate.utm_medium) {
        affiliate.utm_medium.map((item, index) => {
          setUtmMediumList((oldArray) => [
            ...oldArray,
            { value: item, label: item },
          ]);
          return null;
        });
      }
      setUtmCampaignList((oldArray) => [
        ...oldArray,
        { value: "-", label: "-" },
      ]);
      if (affiliate.utm_campaign) {
        affiliate.utm_campaign.map((item, index) => {
          setUtmCampaignList((oldArray) => [
            ...oldArray,
            { value: item, label: item },
          ]);
          return null;
        });
      }
      setUtmTermList((oldArray) => [...oldArray, { value: "-", label: "-" }]);
      if (affiliate.utm_term) {
        affiliate.utm_term.map((item, index) => {
          setUtmTermList((oldArray) => [
            ...oldArray,
            { value: item, label: item },
          ]);
          return null;
        });
      }
      setUtmContentList((oldArray) => [
        ...oldArray,
        { value: "-", label: "-" },
      ]);
      if (affiliate.utm_content) {
        affiliate.utm_content.map((item, index) => {
          setUtmContentList((oldArray) => [
            ...oldArray,
            { value: item, label: item },
          ]);
          return null;
        });
      }
    }
  }, [affiliate]);

  useEffect(() => {
    if (affiliate) {
      var url = `${CURRENT_URL}${selectedPage}?ref=${affiliate.code}`;
      if (selectedUtmSource !== "-") {
        url += `&&utm_source=${selectedUtmSource}`;
      }
      if (selectedUtmMedium !== "-") {
        url += `&&utm_medium=${selectedUtmMedium}`;
      }
      if (selectedUtmCampaign !== "-") {
        url += `&&utm_campaign=${selectedUtmCampaign}`;
      }
      if (selectedUtmTerm !== "-") {
        url += `&&utm_term=${selectedUtmTerm}`;
      }
      if (selectedUtmContent !== "-") {
        url += `&&utm_content=${selectedUtmContent}`;
      }
      setLink(url);
    }
  }, [
    affiliate,
    selectedPage,
    selectedUtmSource,
    selectedUtmMedium,
    selectedUtmCampaign,
    selectedUtmTerm,
    selectedUtmContent,
  ]);

  return (
    <Layout title="Generate Link">
      <Card>
        <div className="space-y-2">
          <div className="flex flex-row justify-start items-center space-x-2">
            <p className="w-[150px]">Page</p>
            <p className="w-[10px]">:</p>
            <div className="w-[200px]">
              <Select2
                list={pageList}
                provideNull={false}
                name="page"
                value={selectedPage}
                onChange={changeHandler}
              />
            </div>
          </div>
          {utmSourceList.length > 1 && (
            <div className="flex flex-row justify-start items-center space-x-2">
              <p className="w-[150px]">UTM Source</p>
              <p className="w-[10px]">:</p>
              <div className="w-[200px]">
                <Select2
                  list={utmSourceList}
                  provideNull={false}
                  name="utm_source"
                  value={selectedUtmSource}
                  onChange={changeHandler}
                />
              </div>
            </div>
          )}
          {utmMediumList.length > 1 && (
            <div className="flex flex-row justify-start items-center space-x-2">
              <p className="w-[150px]">UTM Medium</p>
              <p className="w-[10px]">:</p>
              <div className="w-[200px]">
                <Select2
                  list={utmMediumList}
                  provideNull={false}
                  name="utm_medium"
                  value={selectedUtmMedium}
                  onChange={changeHandler}
                />
              </div>
            </div>
          )}
          {utmCampaignList.length > 1 && (
            <div className="flex flex-row justify-start items-center space-x-2">
              <p className="w-[150px]">UTM Campaign</p>
              <p className="w-[10px]">:</p>
              <div className="w-[200px]">
                <Select2
                  list={utmCampaignList}
                  provideNull={false}
                  name="utm_campaign"
                  value={selectedUtmCampaign}
                  onChange={changeHandler}
                />
              </div>
            </div>
          )}
          {utmTermList.length > 1 && (
            <div className="flex flex-row justify-start items-center space-x-2">
              <p className="w-[150px]">UTM Term</p>
              <p className="w-[10px]">:</p>
              <div className="w-[200px]">
                <Select2
                  list={utmTermList}
                  provideNull={false}
                  name="utm_term"
                  value={selectedUtmTerm}
                  onChange={changeHandler}
                />
              </div>
            </div>
          )}
          {utmContentList.length > 1 && (
            <div className="flex flex-row justify-start items-center space-x-2">
              <p className="w-[150px]">UTM Content</p>
              <p className="w-[10px]">:</p>
              <div className="w-[200px]">
                <Select2
                  list={utmContentList}
                  provideNull={false}
                  name="utm_content"
                  value={selectedUtmContent}
                  onChange={changeHandler}
                />
              </div>
            </div>
          )}
          <div className="flex flex-row justify-start items-center space-x-2">
            <div className="w-[900px]">
              <input
                type="text"
                value={link}
                className="w-full border p-2 outline-none text-xs"
                disabled
              />
            </div>
            <CopyToClipboard
              text={link}
              onCopy={() => {
                toast.success(
                  `${
                    selectedPage === "/landing"
                      ? "Landing Page"
                      : "Checkout Page"
                  } URL copied successfully!`
                );
              }}
            >
              <button className="border border-blue-700 p-0.5 rounded-lg">
                {" "}
                <IoCopy className="text-blue-700" />
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </Card>
    </Layout>
  );
};

const maps = (state) => ({
  affiliate: state.auth.affiliate,
});

export default connect(maps, {})(GenerateLink);
