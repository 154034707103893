import React, { useCallback, useEffect, useState } from "react";
import Card from "../../core/ui/Card";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Cookies from "js-cookie";
import axios from "axios";
import { ECOMMERCE_API_URL } from "../../../stores/actions/urls";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartSale = (props) => {
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Sales Report",
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "Desember",
  ];

  const chartData = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [20, 10, 13, 15, 45, 32, 25],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Dataset 2",
        data: [20, 10, 13, 45, 22, 32, 25],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    setData();
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(
          `${ECOMMERCE_API_URL}/v2/affiliate/closing/self-monthly-recap`,
          config
        )
        .then((res) => {
          setData({
            labels: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "Desember",
            ],
            datasets: [
              {
                label: "Dataset 1",
                data: res.data,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
              },
            ],
          });
        });
    }
  }, []);
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div>
      <Card fullWidth={true}>
        <p className="text-center font-bold">Sales Report</p>
        {data ? <Line options={chartOptions} data={data} /> : <p>Loading...</p>}
      </Card>
    </div>
  );
};

export default ChartSale;
