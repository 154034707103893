import React, { Fragment, useEffect, useState } from "react";
import { IoSave } from "react-icons/io5";
import { connect } from "react-redux";
import Card from "../../components/core/ui/Card";
import Layout from "../../components/core/ui/Layout";
import { loadAffiliate } from "../../stores/actions/auth";
import axios from "axios";
import Cookies from "js-cookie";
import { ECOMMERCE_API_URL } from "../../stores/actions/urls";
import { toast } from "wc-toast";

const Setting = (props) => {
  const [isSending, setIsSending] = useState();
  const { affiliate, loadAffiliate } = props;
  const [formData, setFormData] = useState({
    pixel_id: "",
    tiktok_id: "",
    bank_name: "",
    bank_account_name: "",
    bank_account_number: "",
    wa: "",
    gtm: "",
  });

  const changeHandler = (e) => {
    if (e.target) {
      if (e.target.type === "checkbox") {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    }
  };

  const store = async () => {
    setIsSending(true);
    if (Cookies.get("leofranz")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${Cookies.get("leofranz")}`,
          Accept: "application/json",
        },
      };

      let phone = formData.wa.replace("+", "").trim();
      if (phone.charAt(0) === "0") {
        phone = "62" + phone.substring(1);
      }

      const body = JSON.stringify({
        pixel_id: formData.pixel_id,
        tiktok_id: formData.tiktok_id,
        bank_account: {
          bank: formData.bank_name.trim(),
          name: formData.bank_account_name.trim(),
          account_number: formData.bank_account_number.trim(),
        },
        wa_contact: phone,
        gtm: formData.gtm.trim(),
      });
      await axios
        .post(`${ECOMMERCE_API_URL}/affiliate/setting/`, body, config)
        .then((res) => {
          setIsSending(false);
          toast.success("Data inserted successfully!");
          loadAffiliate(affiliate.email);
        })
        .catch((err) => {
          setIsSending(false);
          toast.success("Data inserted failed!");
        });
    }
  };

  useEffect(() => {
    var loadedFormData = {
      pixel_id: "",
      bank_name: "",
      bank_account_name: "",
      bank_account_number: "",
      wa: "",
      gtm: "",
    };
    if (affiliate) {
      let str_pixel_id = "";
      if (affiliate.pixel_id) {
        affiliate.pixel_id.map((item) => {
          str_pixel_id += item;
          if (
            affiliate.pixel_id.indexOf(item) <
            affiliate.pixel_id.length - 1
          ) {
            str_pixel_id += "\n";
          }
        });
        loadedFormData["pixel_id"] = str_pixel_id;
      } else {
        loadedFormData["pixel_id"] = "";
      }
      let str_tiktok_id = "";
      if (affiliate.tiktok_id) {
        affiliate.tiktok_id.map((item) => {
          str_tiktok_id += item;
          if (
            affiliate.tiktok_id.indexOf(item) <
            affiliate.tiktok_id.length - 1
          ) {
            str_tiktok_id += "\n";
          }
        });
        loadedFormData["tiktok_id"] = str_tiktok_id;
      } else {
        loadedFormData["tiktok_id"] = "";
      }
      if (affiliate.bank_account) {
        loadedFormData["bank_name"] = affiliate.bank_account.bank;
        loadedFormData["bank_account_name"] = affiliate.bank_account.name;
        loadedFormData["bank_account_number"] =
          affiliate.bank_account.account_number;
      }
      if (affiliate.wa_contact) {
        loadedFormData["wa"] = affiliate.wa_contact;
      }
      if (affiliate.gtm) {
        loadedFormData["gtm"] = affiliate.gtm;
      }
    }
    setFormData(loadedFormData);
  }, [affiliate]);

  useEffect(() => {
    document.title = "Setting | Affiliate Quamus";
  }, []);
  return (
    <Layout title="Setting">
      {affiliate ? (
        <Card>
          <button
            disabled={isSending}
            onClick={() => store()}
            className="flex justify-center items-center space-x-1 bg-primary-200 hover:bg-primary-100 text-white py-1 px-2 rounded-md disabled:bg-gray-400 text-sm"
          >
            {isSending ? (
              <div>
                <div
                  className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full mr-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                <p className="inline">Saving...</p>
              </div>
            ) : (
              <Fragment>
                <IoSave />
                <p className="inline">Save</p>
              </Fragment>
            )}
          </button>
          <hr className="my-4" />
          <div className="space-y-4">
            <div className="flex flex-row">
              <div className="w-[200px]">
                <p>Facebook Pixel ID</p>
                <p className="text-xs font-light">
                  *support multiple facebook pixel id, separate by newline
                </p>
              </div>
              <textarea
                className="border border-slate-300 p-2 rounded-md w-[300px] outline-none  text-sm"
                name="pixel_id"
                value={formData.pixel_id}
                rows={4}
                onChange={changeHandler}
              ></textarea>
            </div>
            <div className="flex flex-row">
              <div className="w-[200px]">
                <p>Tiktok Pixel ID</p>
                <p className="text-xs font-light">
                  *support multiple tiktok pixel id, separate by newline
                </p>
              </div>
              <textarea
                className="border border-slate-300 p-2 rounded-md w-[300px] outline-none text-sm"
                name="tiktok_id"
                value={formData.tiktok_id}
                rows={4}
                onChange={changeHandler}
              ></textarea>
            </div>
            <div className="flex flex-row justify-start items-center">
              <div className="w-[200px]">
                <p>Google Tag Manager</p>
              </div>
              <input
                type="text"
                className="border border-slate-300 p-2 rounded-md w-[300px] outline-none  text-sm"
                name="gtm"
                value={formData.gtm}
                placeholder="GTM-AB1234"
                onChange={changeHandler}
              />
            </div>
            <div className="flex flex-row justify-start items-center">
              <div className="w-[200px]">
                <p>Nomor Whatsapp</p>
              </div>
              <input
                type="text"
                className="border border-slate-300 p-2 rounded-md w-[300px] outline-none  text-sm"
                name="wa"
                value={formData.wa}
                placeholder="628xxx"
                onChange={changeHandler}
              />
            </div>
            <div className="space-y-2">
              <p className="font-bold text-center">Data Rekening Bank</p>
              <div className="flex flex-row justify-start items-center">
                <div className="w-[200px]">
                  <p>Nama Bank</p>
                </div>
                <input
                  type="text"
                  className="border border-slate-300 p-2 rounded-md w-[300px] outline-none  text-sm"
                  name="bank_name"
                  value={formData.bank_name}
                  onChange={changeHandler}
                />
              </div>
              <div className="flex flex-row justify-start items-center">
                <div className="w-[200px]">
                  <p>Nama Pemilik Rekening</p>
                </div>
                <input
                  type="text"
                  className="border border-slate-300 p-2 rounded-md w-[300px] outline-none  text-sm"
                  name="bank_account_name"
                  value={formData.bank_account_name}
                  onChange={changeHandler}
                />
              </div>
              <div className="flex flex-row justify-start items-center">
                <div className="w-[200px]">
                  <p>Nomor Rekening</p>
                </div>
                <input
                  type="text"
                  className="border border-slate-300 p-2 rounded-md w-[300px] outline-none  text-sm"
                  name="bank_account_number"
                  value={formData.bank_account_number}
                  onChange={changeHandler}
                />
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <p>Loading...</p>
      )}
    </Layout>
  );
};

const maps = (state) => ({
  affiliate: state.auth.affiliate,
});

export default connect(maps, { loadAffiliate })(Setting);
