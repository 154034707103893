import React from "react";

const Checkbox = (props) => {
  const { title, value, name, onChange } = props;
  return (
    <div>
      <p className="text-slate-500 text-xs font-bold mb-1">
        {title.toUpperCase()}
      </p>

      <input
        type="checkbox"
        className="outline-none border border-slate-300 p-1 font-bold"
        name={name}
        checked={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Checkbox;
